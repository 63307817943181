<template>
  <div>
    <p class="text-medium --white font-weight-medium text-center px-2 mb-8">
      {{ $t('authPage.loggingInWait') }}
    </p>
  </div>
</template>

<script>
export default {
  created () {
    this.$store.dispatch('logLastAction')
  },
  async mounted () {
    try {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('confirmMFAClient', {
            apollo: this.$apollo,
            code: this.$route.query.code,
            token: this.$route.query.token
          })

          await this.$store.dispatch('loadUserInfo')
        })
      })
    } catch (e) {
      await this.$router.push({
        path: '/login'
      })
      return
    }
    this.$router.push({
      path: '/home'
    })
  }
}
</script>
